<script setup lang="ts">
import { ColorModes  } from "~/types/base";

const colorMode = useColorMode();

const changeMode = () => {
  colorMode.preference = colorMode.value === ColorModes.MODE_LIGHT
      ? ColorModes.MODE_DARK
      : ColorModes.MODE_LIGHT
}
</script>

<template>
  <button
      @click.prevent="changeMode"
      class="w-8 h-8 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200 dark:bg-zinc-700 dark:hover:dark:bg-zinc-600 transition-colors duration-300"
      aria-label="Theme switch button"
  >
    <icon name="fm:moon" />
  </button>
</template>