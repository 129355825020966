<script setup lang="ts">
const SHOW_BANNER_KEY = 'show-cookies-banner';

const showBanner = ref(false);
const { isCrawler } = useDevice();

onMounted(() => {
  showBanner.value = localStorage.getItem(SHOW_BANNER_KEY)
      ? localStorage.getItem(SHOW_BANNER_KEY) === 'true'
      : true;
});

const hideBanner = () => {
  localStorage.setItem(SHOW_BANNER_KEY, 'false')
  showBanner.value = false;
};
</script>

<template>
  <div v-if="showBanner && !isCrawler" class="fixed w-full p-8 max-md:p-4 bottom-0 left-0 z-10 bg-gray-200 dark:bg-zinc-600">
    <div class="container">
      <div class="flex items-center justify-center">
        <div class="flex flex-col w-3/5 max-md:w-full gap-4">
          <p>
            FictionMe uses cookies to enhance your reading experience, personalize content, and analyze our traffic. By continuing to browse our site, you agree to our use of cookies.&nbsp;
            <a target="_blank" rel="Cookie Policy" href="/public/cookie-policy.html" class="text-violet-800 dark:text-primary">
              Cookie Policy
            </a> <span class="dark:text-gray-400">|</span>
            <a target="_blank" rel="Terms of use" href="/public/web-privacy-policy.html" class="text-violet-800 dark:text-primary">
              Privacy Policy
            </a>
          </p>
          <div class="flex gap-3 w-full max-w-lg max-md:flex-col">
            <button @click.prevent="hideBanner"  class="flex-1 items-center py-3 max-md:py-2 px-5 max-md:px-4 text-xl bg-zinc-950 hover:bg-zinc-900 rounded-lg text-white text-center">
              I agree
            </button>
            <button @click.prevent="hideBanner" class="flex-1 py-3 max-md:py-2 px-5 max-md:px-4 border-[1px] text-xl border-zinc-950 text-center rounded-lg !text-zinc-900 bg-zinc-900 bg-opacity-10 hover:bg-opacity-20">
              Decline
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>