<script setup lang="ts">
import { useShowMenu } from "~/composible/states";
import { useAuth } from "~/composible/useAuth";

const appConfig = useAppConfig();

const showDropdown = ref(false);
const showMenu = useShowMenu();
const { user, openAuthModal } = useAuth();
const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value
}

const route = useRoute();
const path = computed(() => route.path);

const scrollTop = () => {
  if (route.path === '/') {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}

watch(path, () => {
  showMenu.value = false;
});

const genres = [
  {slug: 'all', name: 'All Novels'},
  {slug:"romance",name:"Romance"},
  {slug:"werewolf",name:"Werewolf"},
  {slug:"fantasy",name:"Fantasy"},
  {slug:"urban",name:"Urban"},
  {slug:"steamy-stories-18",name:"Steamy Stories (18+)"},
  {slug:"young-adult",name:"Young Adult"},
  {slug:"thriller",name:"Thriller"},
  {slug:"lgbtq",name:"LGBTQ+"}
];
</script>

<template>
  <div class="bg-white dark:bg-zinc-800 max-md:fixed max-md:top-[62px] left-0 right-0 bottom-0 transition-transform max-md:overflow-auto duration-300 border-gray-300 dark:dark:border-zinc-700 max-md:border-t z-100" :class="showMenu ? 'max-md:translate-x-0' : 'max-md:translate-x-full'">
    <nav class="md:grid md:grid-flow-col max-md:py-4">
      <nuxt-link @click="scrollTop" to="/" class="header-link max-lg:hidden xl:visible">
        Home
      </nuxt-link>
      <div class="group relative">
        <nuxt-link @click.prevent="toggleDropdown" to="#" class="flex items-center py-4 px-5 font-semibold max-md:flex max-md:items-center max-md:justify-between">
          Novels
          <span class="ml-2 text-xs">&#9662;</span>
        </nuxt-link>

        <div v-if="genres" class="shadow-md md:hidden md:group-hover:block flex-col md:absolute md:top-full bg-white md:rounded-xl overflow-hidden dark:border-zinc-700 dark:bg-zinc-800 md:w-max" :class="showDropdown ? 'max-md:block' : 'max-md:hidden'">
          <router-link :to="`/genres/${genre.slug}-novels`" v-for="genre in genres" class="header-dd-link">
            {{ genre.name }}
          </router-link>
        </div>
      </div>

      <nuxt-link to="/book-reviews" class="header-link">Reviews</nuxt-link>

      <nuxt-link :to="appConfig.authorCabinetUrl" class="header-link">Become a writer</nuxt-link>

      <nuxt-link v-if="user" to="/profile" class="header-link">Profile</nuxt-link>

      <nuxt-link to="/blog" class="header-link">Blog</nuxt-link>

      <nuxt-link v-if="user" to="/library" class="header-link">Library</nuxt-link>

      <nuxt-link to="/search" class="header-link">Search</nuxt-link>
    </nav>

    <div v-if="!user" class="border-t p-4 border-gray-100 dark:border-zinc-700 hidden max-md:block">
      <button @click.prevent="openAuthModal" class="bg-fm-gradient hover:bg-fm-gradient-active py-3 px-8 text-lg !text-white font-semibold rounded-lg cursor-pointer w-full">
        Sign Up
      </button>
    </div>

    <div class="border-t p-4 border-gray-100 dark:border-zinc-700 hidden max-md:block">
      <div class="flex items-center mx-auto rounded-full py-2 px-3 w-max bg-gray-100 dark:bg-zinc-900">
        <color-switcher />
        <span class="ml-3">Dark Mode</span>
      </div>
    </div>
  </div>
</template>